<template>
  <v-radio v-bind="attributes"></v-radio>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { VRadio } from "vuetify/components"

type KRadioProps = Partial<InstanceType<typeof VRadio>["$props"]>

export default defineComponent<KRadioProps, { attributes: any }>({
  props: VRadio.props,
  computed: {
    attributes() {
      const props: KRadioProps = {
        ...(this.$props as Record<string, any>),
        ...this.$attrs,
      }
      // @ts-ignore The prop actually exists here
      props.color = "grey-darken-4"
      return props
    },
  },
})
</script>
