import { acceptHMRUpdate } from "pinia"
import { defineEntryListStore, FunctionOrHandlerObject } from "@/store/factories/entryListStore"
import { api } from "@/logic/api"
import { useAppConfigStore } from "@/store/appConfig"
import { getConstants } from "@/logic/constants"

export type TranslationsListEntry = {
  id: number
  type: string
  unique_id: string
  is_translatable: boolean
  title: string
  status: "missing" | "dependenciesMissing" | "ok"
  updated_at: string
  autotranslation_running: boolean
  nesting_meta: {
    has_children: boolean
    indentation: number
    parent_unique_id?: string
    has_children_matching_search_query: boolean
  }
}

type TranslationStatus = {
  foreign_type: string
  foreign_id: number
  autotranslation_running_since: string
}

export const useTranslationsStore = defineEntryListStore("translations", {
  state: () => {
    return {
      entries: null as null | TranslationsListEntry[],
      totalCount: null as null | number,
      missingTranslationsCount: 0 as number,
      translationStatuses: null as null | TranslationStatus[],
      isLoading: true,
      pagination: {
        page: 1,
        totalCount: 0,
        perPage: 20,
      },
      sort: {
        by: null,
        desc: false,
      },
      filters: [],
      tags: [],
      searchQuery: "",
      language: "" as string,
      types: [] as number[],
      translationStatus: "" as "" | "notTranslated" | "onlyTranslated",
      dataLoadedCallbacks: [] as FunctionOrHandlerObject[],
      expansionState: {} as Record<string, boolean>,
      questionCategories: [] as number[],
      questionFilters: [] as string[],
      courseFilters: ["active"] as string[],
    }
  },
  getters: {
    configWatcher(): Array<any> {
      return [this.language, this.pagination.page, this.pagination.perPage, this.filters, this.tags, this.searchQuery, this.sort.by, this.sort.desc, this.types, this.translationStatus, this.questionCategories, this.questionFilters, this.courseFilters]
    },
    primaryLanguageActive(): boolean {
      const appConfigStore = useAppConfigStore()
      return appConfigStore.settings.defaultLanguage === this.language
    },
    entryListRequestParams(): Record<string, any> {
      const params: Record<string, any> = {
        language: this.language,
        page: this.pagination.page,
        perPage: this.pagination.perPage,
        filters: this.filters,
        tags: this.tags,
        searchQuery: this.searchQuery,
        sort: this.sort,
        types: this.types,
        onlyMissing: this.translationStatus === "notTranslated",
        onlyTranslated: this.translationStatus === "onlyTranslated",
      }
      if (this.types.includes(getConstants().MORPH_TYPES.QUESTION)) {
        params.questionCategories = this.questionCategories
        params.questionFilters = this.questionFilters
      }
      if (this.types.includes(getConstants().MORPH_TYPES.COURSE)) {
        params.courseFilters = this.courseFilters
      }
      return params
    },
    translationStatusByTypeId(): Record<string, any> {
      if (!this.translationStatuses) {
        return {}
      }
      const statuses: Record<string, any> = {}
      this.translationStatuses.forEach((status) => {
        statuses[status.foreign_type + "-" + status.foreign_id] = status
      })

      return statuses
    },
    entriesByUniqueId(): Record<string, TranslationsListEntry> {
      const entriesByUniqueId: Record<string, TranslationsListEntry> = {}
      if (!this.entries) {
        return entriesByUniqueId
      }
      this.entries.forEach((row) => {
        entriesByUniqueId[row.unique_id] = row
      })
      return entriesByUniqueId
    },
    childrenByUniqueId(): Record<string, TranslationsListEntry[]> {
      if (!this.entries) {
        return {}
      }
      /**
       * This is a relatively unoptimized, but simple way to get a list of all the children (including children's children)
       * for each unique parent id
       */
      // First, create a list of all the direct children of each row
      // We need that list in the addChildrenToParent function.
      const shallowChildrenByParent: Record<string, TranslationsListEntry[]> = {}
      this.entries.forEach((row) => {
        const parentUniqueId = row.nesting_meta.parent_unique_id
        if (!parentUniqueId) {
          return
        }
        if (shallowChildrenByParent[parentUniqueId] === undefined) {
          shallowChildrenByParent[parentUniqueId] = []
        }
        shallowChildrenByParent[parentUniqueId].push(row)
      })

      // Now create the final list that contains all deep children for all rows
      const childrenByParent: Record<string, TranslationsListEntry[]> = {}
      const addChildrenToParent = (row: TranslationsListEntry, parentUniqueId: string): void => {
        if (!row.nesting_meta.has_children) {
          return
        }
        const children = shallowChildrenByParent[row.unique_id]
        if (children === undefined) {
          return
        }
        children.forEach((child) => {
          childrenByParent[parentUniqueId].push(child)
          addChildrenToParent(child, parentUniqueId)
        })
      }
      this.entries.forEach((row) => {
        const parentUniqueId = row.nesting_meta.parent_unique_id
        if (parentUniqueId === undefined) {
          return
        }
        if (childrenByParent[parentUniqueId] === undefined) {
          childrenByParent[parentUniqueId] = []
        }
        childrenByParent[parentUniqueId].push(row)
        addChildrenToParent(row, parentUniqueId)
      })
      return childrenByParent
    },
  },
  actions: {
    async updateTranslationStatuses(): Promise<void> {
      const response = await api.get(`/translations/translation-status`)
      this.translationStatuses = response.statuses
    },
  },
})

if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useTranslationsStore, import.meta.hot))
}
