import * as Sentry from "@sentry/vue"
import router from "@/router"
import { Vue } from "@sentry/vue/build/types/types"

export function initSentry(app: Vue | Vue[] | undefined) {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: import.meta.env.VITE_RELEASE,
      trackComponents: true,
      integrations: [Sentry.browserTracingIntegration({ router })],
      tracePropagationTargets: [window.location.host, /^\//],
      tracesSampleRate: 0.01,
    })
  }
}

export function setUserInSentry(userId: number) {
  Sentry.setUser({ id: `${userId}` })
}
