<template>
  <div :class="componentClass" class="s-wrapper">
    <v-autocomplete ref="autocomplete" auto-select-first v-bind="attributes" hide-details="auto" v-on="eventHandlers">
      <template v-if="$slots['item']" #item="scope">
        <slot name="item" v-bind="scope"></slot>
      </template>
      <template v-if="$slots['no-data']" #no-data>
        <slot name="no-data"></slot>
      </template>
      <template v-if="$slots['selection']" #selection="scope">
        <slot name="selection" v-bind="scope"></slot>
      </template>
      <template v-if="$slots['append-item']" #append-item>
        <slot name="append-item"></slot>
      </template>
      <template #append-inner="{ isActive }">
        <VIcon class="v-select__menu-icon" size="12" :icon="isActive ? 'far fa-chevron-down fa-rotate-180' : 'far fa-chevron-down'" />
      </template>
    </v-autocomplete>
    <k-input-error v-if="detailStore" :name="$props.name" :errors="detailStore.errors" :label="$props.label"></k-input-error>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue"
import { VAutocomplete } from "vuetify/components"
import { DetailPageStore } from "@/store/factories/detailPageStore"

const KAutocompleteProps = VAutocomplete.props
KAutocompleteProps.items = {
  type: [Array, Object],
  required: true,
}
KAutocompleteProps.name = {
  required: false,
  default: "",
}

type KAutocompletePropsType = Partial<InstanceType<typeof KAutocompleteProps>>

export default defineComponent<Partial<KAutocompletePropsType>>({
  props: KAutocompleteProps,
  emits: ["update:modelValue", "update:search"],
  setup() {
    return {
      detailStore: inject("detailStore", null) as DetailPageStore | null,
    }
  },
  computed: {
    eventHandlers() {
      const listeners: Record<string, any> = {
        "update:modelValue": ($event: any) => {
          this.$emit("update:modelValue", $event)
        },
      }
      if (this.$props.search !== undefined) {
        listeners["update:search"] = ($event: any) => {
          this.$emit("update:search", $event)
        }
      }
      return listeners
    },
    attributes() {
      const props: KAutocompletePropsType = {
        ...(this.$props as Record<string, any>),
        ...this.$attrs,
        variant: "outlined",
        density: "compact",
        menuIcon: "", // We set-up the menu icon ourselves, so that we can modify it's size
        menuProps: {
          offset: 4,
        },
      }
      delete props.items
      delete props.menu
      // Vuetify doesn't support specifying the items as an object like this:
      // { courses: 'Kurse', tests: 'Tests', users: 'Benutzer' }
      // But that's a really easy way of doing it, so we allow it, and convert the object notation to
      // Vuetify's syntax here
      if (typeof this.$props.items === "object" && !Array.isArray(this.$props.items)) {
        props.items = Object.entries(this.$props.items).map(([key, value]) => {
          return {
            value: key,
            title: value,
          }
        })
      } else {
        props.items = this.$props.items
      }
      delete props.class
      return props
    },
    componentClass() {
      return this.$props.class
    },
  },
})
</script>

<style lang="scss" scoped>
.s-wrapper {
  flex: 1 0;
}
</style>
