<template>
  <div :class="componentClass" class="s-wrapper">
    <v-color-picker elevation="0" v-bind="attributes"></v-color-picker>
    <KInputError v-if="detailStore && detailStore.errors && $props.name" class="mt-1" :name="$props.name" :errors="detailStore.errors" :label="$props.label"></KInputError>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue"
import { VColorPicker } from "vuetify/components"
import { DetailPageStore } from "@/store/factories/detailPageStore"

const KColorPickerProps = VColorPicker.props
KColorPickerProps.name = {
  required: false,
  default: "",
}

export default defineComponent<Partial<typeof KColorPickerProps>>({
  props: KColorPickerProps,
  setup() {
    return {
      detailStore: inject("detailStore", null) as DetailPageStore | null,
    }
  },
  computed: {
    attributes() {
      const props = {
        ...(this.$props as Record<string, any>),
        ...this.$attrs,
      }
      delete props.class
      return props
    },
    componentClass() {
      return this.$props.class
    },
  },
})
</script>

<style lang="scss" scoped>
.s-wrapper {
  flex: 1 0;
}
</style>
