<template>
  <v-alert v-bind="attributes">
    <template #default>
      <div
        class="text-label1"
        :class="{
          'text-grey-darken-4': !attributes.loading,
          'text-white': attributes.loading,
        }"
      >
        <slot />
      </div>
    </template>
    <template v-if="attributes.icon || $slots.icon" #prepend>
      <slot name="icon">
        <VIcon size="18" :color="iconColor">{{ attributes.icon }}</VIcon>
      </slot>
    </template>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { VAlert } from "vuetify/components"

const KAlertProps = VAlert.props
KAlertProps.loading = {
  type: Boolean,
  required: false,
  default: false,
}

export default defineComponent({
  props: KAlertProps,
  computed: {
    attributes(): Record<string, any> {
      const props = {
        ...(this.$props as Record<string, any>),
        ...this.$attrs,
      }
      if (!props.type) {
        props.type = "error"
      }
      if (props.loading) {
        props.color = "grey-darken-4"
        props.style = "color: white;"
      } else {
        props.variant = "outlined"
      }
      if (props.icon === null) {
        props.icon = "far fa-info-circle"
      }
      if (props.loading) {
        props.icon = "far fa-spinner fa-pulse"
      }
      return props
    },
    iconColor(): string {
      if (this.attributes.loading) {
        return "white"
      }
      if (this.attributes.type) {
        return this.attributes.type + ""
      }
      return "pink"
    },
  },
})
</script>
