<template>
  <div :class="componentClass" class="s-wrapper">
    <v-textarea v-bind="attributes" @update:model-value="$emit('update:modelValue', $event)">
      <template v-if="$slots['append-inner']" #append-inner>
        <slot name="append-inner"></slot>
      </template>
      <template v-if="$slots['append']" #append>
        <slot name="append"></slot>
      </template>
      <template v-if="$slots['prepend']" #prepend>
        <slot name="prepend"></slot>
      </template>
      <template v-if="$slots['prepend-inner']" #prepend-inner>
        <slot name="prepend-inner"></slot>
      </template>
    </v-textarea>
    <k-input-error v-if="detailStore" :name="$props.name" :errors="detailStore.errors" :label="$props.label" />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue"
import { VTextarea } from "vuetify/components"
import { DetailPageStore } from "@/store/factories/detailPageStore"

type KTextareaPropsType = Partial<InstanceType<typeof VTextarea>["$props"]>

const KTextareaProps = VTextarea.props
KTextareaProps.errors = {
  required: false,
  default: {},
}
KTextareaProps.hideDetails = {
  required: false,
  default: null,
}
KTextareaProps.name = {
  required: false,
  default: "",
}

export default defineComponent<Partial<typeof KTextareaProps>>({
  props: KTextareaProps,
  emits: ["update:modelValue"],
  setup() {
    return {
      detailStore: inject("detailStore", null) as DetailPageStore | null,
    }
  },
  computed: {
    attributes() {
      const props: KTextareaPropsType = {
        ...(this.$props as Record<string, any>),
        ...this.$attrs,
        variant: "outlined",
        density: "compact",
        hideDetails: true,
      }
      delete props.class
      return props
    },
    componentClass() {
      return this.$props.class
    },
  },
})
</script>

<style lang="scss" scoped>
.s-wrapper {
  flex: 1 0;
}
</style>
