<template>
  <div :class="{ '-isFocused': isFocused }" class="s-wrapper">
    <div v-if="props.label" class="s-label">{{ label }}</div>
    <div :key="`${kRichEditorKey}-${editorKey}`">
      <editor v-model="content" tinymce-script-src="/vendor/tinymce/js/tinymce/tinymce.min.js" :init="editorOptions" :disabled="isDisabled" @blur="handleBlur" @change="handleChange" @focus="handleFocus" @init="setTinymceInstance" />
    </div>
    <div class="s-feedback">
      <KInputError v-if="detailStore" :name="$props.name" :errors="detailStore.errors" :label="label" />

      <div class="s-counter">{{ $t("global.characters", { count: characterCount }) }}</div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends string | null">
import Editor from "@tinymce/tinymce-vue"
import { computed, ComputedRef, inject, InjectionKey, ref, watch } from "vue"
import { makeDefaultOptions } from "@/components/KRichTextEditor/tinyMCEOptions"
import { DetailPageStore } from "@/store/factories/detailPageStore"
import { default as TinyMCE, RawEditorOptions } from "../../public/vendor/tinymce/js/tinymce/tinymce"

const detailStore = inject("detailStore", null) as DetailPageStore | null
const props = withDefaults(
  defineProps<{
    modelValue: T
    name?: string
    tinymceOptions?: Partial<RawEditorOptions>
    label?: string
    errors?: Record<string, any>
    height?: number
    disabled?: boolean | null
    kRichEditorKey?: any
  }>(),
  {
    height: 500,
    disabled: null,
    label: "",
    name: undefined,
    kRichEditorKey: 0,
    tinymceOptions: () => ({}),
    errors: () => {
      return {}
    },
  },
)
const editorKey = ref(0)
const emit = defineEmits(["update:modelValue", "input", "change"])
const tinymceInstance = ref<typeof TinyMCE | null>(null)
const content = computed({
  get() {
    return props.modelValue
  },
  set(value: T) {
    emit("update:modelValue", value)
  },
})
const contentDiv = document.createElement("div")
function getCharacterCount(htmlString: string) {
  if (!htmlString) {
    return 0
  }

  // Set the HTML content
  contentDiv.innerHTML = htmlString

  // Get the text content, preferring textContent over innerText
  const text = contentDiv.textContent || contentDiv.innerText || ""

  // Trim and return length
  return text.trim().length
}
const characterCount = computed(() => {
  if (!content.value) {
    return 0
  }
  return getCharacterCount(content.value.toString())
})

const isFocused = ref(false)

const form = inject<{ isDisabled: ComputedRef<boolean> } | undefined>(Symbol.for("vuetify:form") as InjectionKey<{ isDisabled: ComputedRef<boolean> } | undefined>, undefined)
const isDisabled = computed(() => {
  if (props.disabled === null) {
    if (!form) {
      return false
    }
    return form.isDisabled.value
  }
  return props.disabled
})

const editorOptions = computed(() => {
  const options = makeDefaultOptions()
  options.disabled = isDisabled.value
  options.height = props.height
  return {
    ...options,
    ...(props.tinymceOptions ?? {}),
  }
})

const previousEditorOptions = ""
watch([editorOptions], () => {
  const editorOptionsString = JSON.parse(JSON.stringify(editorOptions.value))
  if (editorOptionsString === previousEditorOptions) {
    return
  }
  editorKey.value++
})

let currentEditorContent = ""

function setTinymceInstance(_: any, instance: typeof TinyMCE) {
  tinymceInstance.value = instance
}

function handleFocus() {
  currentEditorContent = content.value || ""
  isFocused.value = true
}
function handleBlur() {
  if (currentEditorContent !== content.value) {
    // We emit the input event here, because this gives us a semi-reliable way to only emit the input event when the user
    // actually changed something and we didn't change content from the outside.
    emit("input")
  }
  isFocused.value = false
}

function handleChange() {
  emit("change")
}
</script>

<style lang="scss" scoped>
.s-wrapper {
  position: relative;
}

.s-feedback {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.s-counter {
  text-align: right;
  font-size: 12px;
  color: $color-grey3;
  flex: 1;
}

.s-label {
  font-size: 10.5px;
  color: $color-grey3;
  position: absolute;
  z-index: 999;
  top: -8px;
  padding: 0 5px;
  background: white;
  margin-left: 10px;
  pointer-events: none;
}
</style>
