<template>
  <KButton v-bind="attributes">
    <template #default>
      {{ label }}
      <v-menu activator="parent" location="bottom" offset="4">
        <v-list>
          <v-list-item v-for="(itemLabel, value) in items" :key="value" @click="handleClick(value)">
            <v-list-item-title>{{ itemLabel }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template #append><v-icon :size="12">far fa-chevron-down</v-icon></template>
  </KButton>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import KButton from "@/components/KButton.vue"

const KDropDownButtonProps = { ...KButton.props }
KDropDownButtonProps.items = {
  type: Object,
  required: true,
}
KDropDownButtonProps.modelValue = {
  type: String,
  required: false,
  default: null,
}
KDropDownButtonProps.primary = {
  type: Boolean,
  required: false,
  default: false,
}

KDropDownButtonProps.buttonLabel = {
  type: String,
  required: false,
  default: null,
}

export default defineComponent<Partial<typeof KDropDownButtonProps>, { attributes: any }>({
  props: KDropDownButtonProps,
  emits: ["update:modelValue", "select"],
  computed: {
    attributes() {
      let variant = this.$props.variant
      if (!variant || variant === "elevated") {
        variant = "flat"
      }
      const props = {
        ...(this.$props as Record<string, any>),
        ...this.$attrs,
      }
      if (!props.color && variant === "flat") {
        if (props.primary) {
          props.color = "primary"
        } else {
          props.color = "secondary"
        }
      }
      if (props.disabled) {
        props.color = ""
      }
      return props
    },
    label() {
      return this.buttonLabel ? this.buttonLabel : this.items[this.modelValue]
    },
  },
  methods: {
    handleClick(value: string) {
      this.$emit("select", value)
      this.$emit("update:modelValue", value)
    },
  },
})
</script>
