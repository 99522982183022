import { useAppProfileConfigStore } from "@/store/appProfileConfig"
import { SharedAppProfileSettings } from "../../../types/appProfileSettings"
import { getSelectedLanguage } from "@/logic/i18n"
import { api } from "@/logic/api"
import { RawEditorOptions } from "../../../public/vendor/tinymce/js/tinymce/tinymce"

export function makeDefaultOptions(): Partial<RawEditorOptions> {
  return {
    height: 500,
    min_height: 200,
    resize: true,
    menubar: false,
    statusbar: false,
    relative_urls: false,
    language: getSelectedLanguage().value ?? "de",
    contextmenu: false,
    paste_block_drop: false,
    paste_data_images: false,
    paste_as_text: false,
    branding: false,
    skin: "keelearning",
    content_style: getContentStyle(),
    external_plugins: {
      autoresize: "/vendor/tinymce/js/tinymce/plugins/autoresize/plugin.min.js",
      emoticons: "/vendor/tinymce/js/tinymce/plugins/emoticons/plugin.min.js",
      fullscreen: "/vendor/tinymce/js/tinymce/plugins/fullscreen/plugin.min.js",
      image: "/vendor/tinymce/js/tinymce/plugins/image/plugin.min.js",
      media: "/vendor/tinymce/js/tinymce/plugins/media/plugin.min.js",
      link: "/vendor/tinymce/js/tinymce/plugins/link/plugin.min.js",
      lists: "/vendor/tinymce/js/tinymce/plugins/lists/plugin.min.js",
      table: "/vendor/tinymce/js/tinymce/plugins/table/plugin.min.js",
    },
    max_height: 700,
    plugins: [],
    entity_encoding: "raw",
    disabled: false,
    toolbar1: "undo redo | styles fontsize | forecolor backcolor bold italic link | removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent image media table emoticons | fullscreen",
    style_formats: [
      {
        title: "Headers",
        items: [
          { title: "Header 1", format: "h2" },
          { title: "Header 2", format: "h3" },
        ],
      },
      {
        title: "Inline",
        items: [
          { title: "Bold", icon: "bold", format: "bold" },
          { title: "Italic", icon: "italic", format: "italic" },
          { title: "Underline", icon: "underline", format: "underline" },
          { title: "Strikethrough", icon: "strike-through", format: "strikethrough" },
          { title: "Superscript", icon: "superscript", format: "superscript" },
          { title: "Subscript", icon: "subscript", format: "subscript" },
          { title: "Code", icon: "sourcecode", format: "code" },
        ],
      },
      {
        title: "Blocks",
        items: [
          { title: "Paragraph", format: "p" },
          { title: "Blockquote", format: "blockquote" },
          { title: "Div", format: "div" },
          { title: "Pre", format: "pre" },
        ],
      },
      {
        title: "Alignment",
        items: [
          { title: "Left", icon: "align-left", format: "alignleft" },
          { title: "Center", icon: "align-center", format: "aligncenter" },
          { title: "Right", icon: "align-right", format: "alignright" },
          { title: "Justify", icon: "align-justify", format: "alignjustify" },
        ],
      },
    ],
    images_upload_handler: async function (image) {
      const formData = new FormData()
      formData.append("image", image.blob(), image.filename())

      return await api.post("/global/wysiwyg/uploads", formData).then((response) => {
        if (response.success) {
          return response.url
        } else {
          throw response.error
        }
      })
    },
    color_map: getColorOptions(),
  }
}

function getColorOptions() {
  const appProfileConfigStore = useAppProfileConfigStore()
  const colorOptions: string[] = ["#000000", "Black"]
  const colorSettings: (keyof SharedAppProfileSettings)[] = [
    "color_primary",
    "color_secondary",
    "color_success",
    "color_medium_success",
    "color_error",
    "color_gold",
    "color_silver",
    "color_bronze",
    "color_highlight",
    "color_dark",
    "color_dark_medium_emphasis",
    "color_dark_light_emphasis",
    "color_soft_highlight",
    "color_divider",
    "color_text_highlight",
  ]
  colorSettings.forEach((colorSetting) => {
    if (!appProfileConfigStore.settings[colorSetting]) {
      return
    }
    colorOptions.push(appProfileConfigStore.settings[colorSetting] as string)
    colorOptions.push(colorSetting.replace("color_", "").replace("_", ""))
  })
  const defaultColors = [
    "993300",
    "Burnt orange",
    "333300",
    "Dark olive",
    "003300",
    "Dark green",
    "003366",
    "Dark azure",
    "000080",
    "Navy Blue",
    "333399",
    "Indigo",
    "333333",
    "Very dark gray",
    "800000",
    "Maroon",
    "FF6600",
    "Orange",
    "808000",
    "Olive",
    "008000",
    "Green",
    "008080",
    "Teal",
    "0000FF",
    "Blue",
    "666699",
    "Grayish blue",
    "808080",
    "Gray",
    "FF0000",
    "Red",
    "FF9900",
    "Amber",
    "99CC00",
    "Yellow green",
    "339966",
    "Sea green",
    "33CCCC",
    "Turquoise",
    "3366FF",
    "Royal blue",
    "800080",
    "Purple",
    "999999",
    "Medium gray",
    "FF00FF",
    "Magenta",
    "FFCC00",
    "Gold",
    "FFFF00",
    "Yellow",
    "00FF00",
    "Lime",
    "00FFFF",
    "Aqua",
    "00CCFF",
    "Sky blue",
    "993366",
    "Red violet",
    "FFFFFF",
    "White",
    "FF99CC",
    "Pink",
    "FFCC99",
    "Peach",
    "FFFF99",
    "Light yellow",
    "CCFFCC",
    "Pale green",
    "CCFFFF",
    "Pale cyan",
    "99CCFF",
    "Light sky blue",
    "CC99FF",
    "Plum",
  ]
  return [...colorOptions, ...defaultColors]
}

function getContentStyle() {
  const appProfileConfigStore = useAppProfileConfigStore()
  const textColor = appProfileConfigStore.settings["color_dark"] as unknown as string
  return `
  body, html {
    color: ${textColor};
  }
  `
}
